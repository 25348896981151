<template>
  <div class="marks">
    <section class="section yellow fullheight">
      <div class="text">
        <img class="fonts-img" src="@/assets/marks/fonts.svg" alt="">

        <a href="https://assets.ctfassets.net/4e7628tn6dwc/bbXSKvqLbvqNOM0GTbQ74/187fc1809f2bd83bf253b5f91b899495/Mood_Marks_Fonts.zip">
          <button class="button">
            DOWNLOAD
          </button>
        </a>

        <p class="text-small">
          Download five diffrent fonts for your computer that support Mood Marks here.-)
        </p>
      </div>
    </section>

    <section class="section">
      <div class="text">
        Below, you find a collection of different types of texts where Mood Marks have
        been incorporated. All contributors work with written communication in some way.
        They were asked to experiment with Mood Marks, reflect on the evolution of
        written communication in their field and what role these new symbols can play.
      </div>

      <a href="https://assets.ctfassets.net/4e7628tn6dwc/7iqzpAlrDJ2CvGd01HN2pX/22769e9628799666c6361596d558ae55/MoodMarksPaper.pdf">
        <button class="button">
          READ PAPER
        </button>
      </a>

      <img class="paper" width="820" src="@/assets/marks/paper.jpg" alt="">
    </section>

    <section class="section small yellow">
      <img width="716" src="@/assets/marks/iphone.jpg" alt="">
    </section>

    <section class="section small">
      <img width="940" src="@/assets/marks/bubbla.jpg" alt="">
    </section>

    <section class="section small yellow">
      <img width="716" src="@/assets/marks/twitter.jpg" alt="">
    </section>

    <section class="section small">
      <img width="940" src="@/assets/marks/bubbla2.jpg" alt="">
    </section>

    <section class="section small yellow">
      <img width="940" src="@/assets/marks/gmail.jpg" alt="">
    </section>

    <section class="section small">
      <img width="940" src="@/assets/marks/bubbla3.jpg" alt="">
    </section>

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Marks',

  components: {
    AppFooter,
  },
};
</script>

<style lang="scss" scoped>
.section {
  @include section;

  img {
    @include under-small {
      width: 300px;
    }
  }
}

.fullheight {
  text-align: center;
}

.section .fonts-img {
  display: none;

  @include large {
    display: block;
  }
}

.text-small {
  font-size: 18px;
  max-width: 380px;
  margin: auto;
  margin-top: 40px;
}

.text {
  max-width: 900px;
  font-size: 14px;
  padding: 40px;

  @include large {
    font-size: 36px;
  }
}

.paper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 80px 0;
}

.button {
  border: 0;
  background: black;
  color: white;
  height: 60px;
  width: 240px;
  display: block;
  margin: auto;
  margin-top: 80px;
  font-family: 'Font';
  font-size: 24px;
}
</style>
